<template>
	<h1>{{pendingServices ? 'Pending Services' : 'Manage Services'}}</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else-if="services === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<div id="service-list-wrapper">
			<div id="filters" class="form">
				<div v-if="isInternalUser">
					<label for="provider-selection">Provider</label>
					<select id="provider-selection" v-model="displayProvider" @change="loadServices(true, true)">
						<option value="all">All</option>
						<option value="OctaneCorporate">Telco in a Box Corporate</option>
						<option value="OctaneCorporateLegacy">Telco in a Box Corporate Legacy</option>
						<option value="OctaneResidential">Telco in a Box Residential</option>
					</select>
				</div>
				<div v-if="!pendingServices">
					<label for="service-type-selection">Service Type</label>
					<select id="service-type-selection" v-model="displayServiceType" @change="loadServices(true, true)">
						<option value="all">All</option>
						<option value="NBN">NBN</option>
						<option value="Mobile">Mobile</option>
					</select>
				</div>
				<div>
					<label for="status-selection">Status</label>
					<select id="status-selection" v-model="displayStatus" @change="loadServices(true, true)">
						<template v-if="pendingServices">
							<option value="all">All</option>
							<option value="pending">Pending</option>
							<option value="shipped">Shipped</option>
						</template>
						<template v-else>
							<option value="all">All</option>
							<option value="current">Current</option>
							<option value="pending">Pending</option>
							<option value="provisioning">Provisioning</option>
							<option value="active">Active</option>
							<option value="suspended">Suspended</option>
							<option value="cancelled">Cancelled</option>
						</template>
					</select>
				</div>
				<button v-if="!pendingServices && canCreate" type="button" @click="createService">Create New Service</button>
			</div>
			<div v-show="loadingServices" class="loading-indicator">
				<img src="@/assets/images/loading.gif">
			</div>
			<div id="service-list" :class="{hidden: loadingServices, pending: pendingServices}">
				<div v-if="services.length == 0" class="no-services">
					There are no services to display.
				</div>
				<table v-else class="data-table">
					<tr>
						<th>Service ID</th>
						<th v-if="pendingServices">Customer Name</th>
						<th>Service Name</th>
						<th v-if="!pendingServices">Service Type</th>
						<th>Plan Name</th>
						<th v-if="isInternalUser">Provider</th>
						<template v-if="pendingServices">
							<th>SIM Card Number</th>
							<th>Tracking Number</th>
						</template>
						<th>Status</th>
						<th :colspan="(canEdit || canEditPending || canActivate) ? 4 : 1">{{(canEdit || canEditPending || canActivate) ? 'Actions' : (pendingServices ? 'Address Details' : 'View Details')}}</th>
					</tr>
					<tr v-for="(service, index) in services" :class="{'inactive-service': ['cancelled', 'disconnected'].includes(service.status), 'pending-service': checkPending(service.status), 'provisioning-service': (service.status == 'provisioning'), 'suspended-service': (service.status == 'suspended')}">
						<td>{{service.id}}</td>
						<td v-if="pendingServices">{{service.customer_details.customer_name}}</td>
						<td>{{service.service_name}}</td>
						<td  v-if="!pendingServices">{{service.service_type}}</td>
						<td>{{service.plan_details.plan_name}}</td>
						<td v-if="isInternalUser">{{formatProvider(service.provider)}}</td>
						<template v-if="pendingServices">
							<td :class="{'empty-cell': !service.sim_card_number}">{{service.sim_card_number ?? 'N/A'}}</td>
							<td :class="{'empty-cell': !service.tracking_number}">
								<a v-if="service.tracking_number" :href="`https://auspost.com.au/mypost/track/#/details/${service.tracking_number}`" target="_blank">{{service.tracking_number}}</a>
								<template v-else>N/A</template>
							</td>
						</template>
						<selectable-value-cell v-if="service.service_type == 'Mobile' && checkActive(service.status)" :record-index="index" :selectable-values="validStatuses()" :selecting-record-index="changeStatusIndex" :is-selecting="changingStatus" :allow-selection="canEdit" :is-error="changeStatusErrors[index]" selectable-value-class-prefix="service-status" @show-selection="display => changeStatusIndex = display ? index : null" @save-selection="changeStatus">{{formatStatus(service.status)}}</selectable-value-cell>
						<td v-else>{{formatStatus(service.status)}}</td>
						<td class="action-cell info-cell" @click="viewService(service)" :title="pendingServices ? 'View Address Details' : 'View Details'">&#x1F6C8;</td>
						<td v-if="canEdit || canEditPending" class="edit-cell" @click="editService(service, $event)" :title="pendingServices ? 'Edit Shipping Details' : 'Edit Service'">&#x1F589;</td>
						<td v-if="canCancel" class="delete-cell" @click="cancelService(service)" title="Cancel Service">X</td>
						<selectable-action-cell v-if="hasServicesWithAvailableActions" :record-index="index" :selectable-actions="validActions(service)" :selecting-record-index="actionIndex" @show-selection="display => actionIndex = display ? index : null" title="Additional Actions"></selectable-action-cell>
					</tr>
				</table>
				<pagination v-if="!pendingServices" v-model="displayPage" :data="services" @page-changed="loadServices"></pagination>
			</div>
		</div>
		<service-details v-if="!pendingServices && viewingService" :service-id="viewingService" @close="viewingService = null"></service-details>
		<pending-service-details v-if="pendingServices && viewingService" :service-id="viewingService" @close="viewingService = null"></pending-service-details>
		<edit-service-form v-if="!pendingServices && editingService" :service-id="editedService" @close="editingService = false" @completed="loadServices(false)"></edit-service-form>
		<edit-pending-service-form v-if="pendingServices && editingService" :service-id="editedService" @close="editingService = false" @completed="loadServices(false)"></edit-pending-service-form>
		<cancel-service-confirmation v-if="cancellingService" :service="cancellingService" @close="cancellingService = null" @completed="loadServices(false)"></cancel-service-confirmation>
		<activate-service-form v-if="activatingService" :service-id="activatingService" @close="activatingService = null" @completed="loadServices(false)"></activate-service-form>
		<change-mobile-number-form v-if="changingMobileNumber" :service-id="changingMobileNumber" @close="changingMobileNumber = null"></change-mobile-number-form>
		<replace-sim-card-form v-if="replacingSimCard" :service-id="replacingSimCard" @close="replacingSimCard = null"></replace-sim-card-form>
		<service-settings-form v-if="updatingServiceSettings" :service-id="updatingServiceSettings" @close="updatingServiceSettings = null"></service-settings-form>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import SelectableValueCell from '@/components/SelectionCells/SelectableValueCell';
	import SelectableActionCell from '@/components/SelectionCells/SelectableActionCell';
	import ServiceDetails from '@/components/Modals/Services/ServiceDetails';
	import PendingServiceDetails from '@/components/Modals/Services/PendingServiceDetails';
	import EditServiceForm from '@/components/Modals/Services/EditServiceForm';
	import EditPendingServiceForm from '@/components/Modals/Services/EditPendingServiceForm';
	import CancelServiceConfirmation from '@/components/Modals/Services/CancelServiceConfirmation';
	import ActivateServiceForm from '@/components/Modals/Services/ActivateServiceForm';
	import ChangeMobileNumberForm from '@/components/Modals/Services/ChangeMobileNumberForm';
	import ReplaceSimCardForm from '@/components/Modals/Services/ReplaceSimCardForm';
	import ServiceSettingsForm from '@/components/Modals/Services/ServiceSettingsForm';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			pendingServices: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				services: null,
				displayProvider: 'all',
				displayServiceType: 'all',
				displayStatus: null,
				loadingServices: false,
				dataError: false,
				viewingService: null,
				editingService: false,
				editedService: null,
				cancellingService: null,
				activatingService: null,
				changingMobileNumber: null,
				replacingSimCard: null,
				updatingServiceSettings: null,
				changeStatusIndex: null,
				changingStatus: false,
				changeStatusErrors: {},
				actionIndex: null,
				displayPage: 1
			}
		},
		computed: {
			canCreate() { // Used to determine whether the authenticated user has the appropriate permission to create services (services also can't be created for cancelled customers).
				return (!this.pendingServices && this.hasPermission('services', 'create') && !this.customerCancelled);
			},
			canEdit() { // Used to determine whether the authenticated user has the appropriate permission to edit services for a customer (services also can't be edited for cancelled customers).
				return (!this.pendingServices && this.hasPermission('services', 'edit') && !this.customerCancelled);
			},
			canActivate() { // Used to determine whether the authenticated user has the appropriate permission to activate services for a customer (services also can't be activated for cancelled customers).
				return (!this.pendingServices && this.hasPermission('services', 'activate', true) && !this.customerCancelled);
			},
			canCancel() { // Used to determine whether the authenticated user has the appropriate permission to cancel services (services also can't be cancelled for cancelled customers).
				return (!this.pendingServices && this.hasPermission('services', 'cancel') && !this.customerCancelled);
			},
			canEditPending() { // Used to determine whether the authenticated user has the appropriate permission to edit the shipping details for a pending service.
				return (this.pendingServices && this.hasPermission('services', 'edit-pending'));
			},
			hasServicesWithAvailableActions() { // Used to determine if any services have actions available in the actions dropdown menu.
				const servicesWithAvailableActions = this.services.filter(service => {
					return (service.service_type == 'Mobile' && (this.canEdit && this.checkActive(service.status)) || (this.canActivate && this.checkPending(service.status)));
				});
				
				return (servicesWithAvailableActions.length > 0);
			},
			customerCancelled() {
				return (this.managingCustomer.status == 'cancelled');
			},
			apiEndpoint() { // Determines the API endpoint to use depending on whether we're displaying the list of pending services, or the list of services for a specific customer.
				return this.pendingServices ? 'pending-services' : `customers/${this.managingCustomer.id}/services`;
			},
			...mapGetters(['managingCustomer', 'isInternalUser', 'hasPermission'])
		},
		components: {
			Pagination, SelectableValueCell, SelectableActionCell, ServiceDetails, PendingServiceDetails, EditServiceForm, EditPendingServiceForm, CancelServiceConfirmation, ActivateServiceForm, ChangeMobileNumberForm, ReplaceSimCardForm, ServiceSettingsForm
		},
		async created() { // When the page is loaded, get the list of services.
			this.displayStatus = this.pendingServices ? 'all' : 'current'; // If we're displaying pending services, default to displaying all pending services. If we're displaying services for a specific customer, default to displaying active services.
			await this.loadServices();
		},
		methods: {
			async loadServices(refresh = true, resetPagination = false) { // Performs the API request to get the list of services.
				// If the pagination needs to be reset, set the displayed page to Page 1.
				if(resetPagination) {
					this.displayPage = 1;
				}
				
				// If we're visually refreshing the page (rather than stealthily updating the service list), display the loading indicator.
				if(refresh) {
					this.loadingServices = true;
				}
				
				// Generate the query string for the API request based on the filters selected.
				let queryString = !this.pendingServices ? `?page=${this.displayPage}` : ''; // For the customer services page, include the pagination in the query string. This isn't supported for the pending services page.
				const filters = {'provider': this.displayProvider, 'service-type': this.displayServiceType, 'status': this.displayStatus};
				for(const filter in filters) {
					const filterValue = filters[filter];
					if(filterValue != 'all') { // Each query string argument is only required if the filter is actually applied.
						const prefix = (queryString == '') ? '?' : '&';
						queryString += `${prefix}${filter}=${filterValue}`;
					}
				}
				
				// Perform the API request to get the list of services.
				try {
					const response = await this.HTTP.get(this.apiEndpoint + queryString);
					this.services = response.data.data;
				} catch(error) { // If there was an error obtaining the service list, display the generic error message.
					this.dataError = true;
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.loadingServices = false;
				}
			},
			createService() { // Displays the modal for creating a new service.
				this.editedService = null;
				this.editingService = true;
			},
			editService(service, event) { // Displays the edit service modal for the given service (if the service isn't cancelled).
				if(!event.currentTarget.parentElement.classList.contains('inactive-service')) {
					this.editedService = service.id;
					this.editingService = true;
				}
			},
			cancelService(service) { // Displays the deletion confirmation modal for the given service.
				this.cancellingService = service;
			},
			activateService() { // Displays the activate service modal for the service identified by the actionIndex property.
				const service = this.services[this.actionIndex];
				this.activatingService = service.id;
			},
			changeMobileNumber() { // Displays the modal for changing the mobile number of the service identified by the actionIndex property.
				const service = this.services[this.actionIndex];
				this.changingMobileNumber = service.id;
			},
			replaceSimCard() { // Displays the modal for updating the SIM Card Number of the service identified by the actionIndex property.
				const service = this.services[this.actionIndex];
				this.replacingSimCard = service.id;
			},
			updateServiceSettings() { // Displays the modal for updating advanced settings of the service identified by the actionIndex property.
				const service = this.services[this.actionIndex];
				this.updatingServiceSettings = service.id;
			},
			viewService(service) { // Displays the modal for viewing the details of a service.
				this.viewingService = service.id;
			},
			async changeStatus(newStatus) { // Performs the API request to update the status for the service identified by the changeStatusIndex property.
				const service = this.services[this.changeStatusIndex];
				if(newStatus != service.status) { // Only continue if the selected status is different from the current status.
					// Mark the status change as pending to display the loading indicator, and remove the status change error if it is displayed.
					this.changingStatus = true;
					this.changeStatusErrors[this.changeStatusIndex] = false;
					
					try {
						// Perform the API request to update the status of the given service.
						await this.HTTP.post(`customers/${this.managingCustomer.id}/services/${service.id}/status`, {status: newStatus});
						if(service == this.services[this.changeStatusIndex]) { // Update the status displayed for the service (only if the list of services hasn't been refreshed since this API request was initiated).
							service.status = newStatus;
						}
					} catch(error) { // If there was an error updating the customer status, handle it as appropriate depending on the error message (only if the list of services hasn't been refreshed since this API request was initiated).
						if(service == this.services[this.changeStatusIndex]) {
							if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The new status must be different from the current status.") { // If the error is that the new status must be different from the current status, then it simply means that the status has changed since the service list was loaded, so just quietly update the status that is displayed.
								service.status = newStatus;
							} else { // For any other error, display the error message below the status name.
								this.changeStatusErrors[this.changeStatusIndex] = true;
							}
						}
					} finally { // Regardless of whether the API request was successful, hide both the status dropdown and loading indicator.
						this.changingStatus = false;
						this.changeStatusIndex = null;
					}
				} else { // If the selected status is the same as the current status, simply hide the status dropdown.
					this.changeStatusIndex = null;
				}
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			formatProvider(provider) { // Given the provider for a service, returns the user-friendly representation.
				switch(provider) {
					case 'OctaneCorporate':
						return 'Telco in a Box Corporate';
					case 'OctaneCorporateLegacy':
						return 'Telco in a Box Corporate Legacy';
					case 'OctaneResidential':
						return 'Telco in a Box Residential';
					default:
						return provider;
				}
			},
			formatStatus(status) { // Given the status for a service, returns the user-friendly representation.
				// Normalise the "disconnected" status to show "cancelled" instead.
				if(status == 'disconnected') {
					status = 'cancelled';
				}
				
				// Return the given status name with the first character capitalised.
				return this.capitaliseFirstLetter(status);
			},
			checkActive(status) { // Given the status for a service, returns a boolean indicating whether the service is considered to be an active service.
				return ['active', 'suspended'].includes(status);
			},
			checkPending(status) { // Given the status for a service, returns a boolean indicating whether the service is considered to be a pending service.
				return ['pending', 'shipped'].includes(status);
			},
			validStatuses() { // Returns the list of service statuses formatted for use in the status selection cells.
				// Set the list of valid values for the underlying API.
				const apiValues = ['active', 'suspended'];
				
				// Create an object to map each underlying API value to the display value, which is simply the API value with the first character capitalised..
				const values = {};
				for(const value of apiValues) {
					values[value] = this.capitaliseFirstLetter(value);
				}
				
				return values;
			},
			validActions(service) { // Returns the list of actions that are available for the given services, formatted for use in the action selection cells.
				const actions = {};
				if(service.service_type == 'Mobile') {
					if(this.canActivate && this.checkPending(service.status)) {
						actions['Activate Service'] = this.activateService;
					}
					if(this.canEdit && this.checkActive(service.status)) {
						actions['Change Mobile Number'] = this.changeMobileNumber;
						actions['Replace SIM Card'] = this.replaceSimCard;
						actions['Service Settings'] = this.updateServiceSettings;
					}
				}
				
				return actions;
			}
		}
	}
</script>

<style scoped lang="scss">
	#service-list-wrapper {
		width:max-content;
		margin:0 auto;
	}
	
	#service-list.hidden {
		visibility:hidden;
	}
	
	#filters {
		text-align:right;
		margin-bottom:20px;
		
		div {
			text-align:left;
			display:inline-block;
			margin-bottom:0;
		}
		
		label {
			display:block;
		}
		
		div, button {
			margin-left:20px;
		}
	}
	
	.no-services {
		text-align:center;
	}
	
	#service-list:not(.pending) .pending-service, #service-list:not(.pending) .provisioning-service {
		color:#62A8FF;
	}
	
	.suspended-service, .selection-cell:deep(.service-status-suspended) {
		color:#FF0000;
	}
	
	.selection-cell:deep(.service-status-active) {
		color:var(--main-accent-color);
	}
	
	.selection-cell:deep(.update-error) {
		margin-bottom:-36px;
	}
	
	.inactive-service {
		color:var(--inactive-record-color);
		
		.edit-cell {
			opacity:50%;
			cursor:default;
		}
	}
	
	.empty-cell {
		color:var(--inactive-record-color);
	}
	
	.info-cell {
		background-color:var(--main-accent-color);
		color:#FFFFFF;
		font-size:3rem;
		font-weight:normal;
	}
</style>