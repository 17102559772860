<template>
	<modal-view :title="creatingService ? 'Create Service' : 'Edit Service'" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="savingService || loadingService" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="serviceSaved" class="success-message">Service {{creatingService ? 'Created' : 'Updated'}} Successfully</div>
		<div v-if="displayForm" v-show="!savingService">
			<div class="data-form" v-if="creatingService">
				<div>
					<label for="service-type">Service Type</label>
					<span class="required">*</span>
					<select id="service-type" v-model="serviceType" @change="errorMessage = null">
						<option value="" disabled>Select...</option>
						<option value="Mobile">Mobile</option>
						<option value="NBN">NBN</option>
					</select>
				</div>
			</div>
			<div v-if="creatingService" v-show="serviceType == 'NBN'">
				<h3>Perform a Service Qualification</h3>
				<service-qualification mode="service" :customer-type="managingCustomer.customer_type" @success="addressSelected" @reset="addressSelected"></service-qualification>
			</div>
			<template v-if="availablePlans !== null">
				<template v-if="availablePlans.length > 0">
					<div id="plan-group-wrapper" class="data-form" v-if="displayPlanGroupSelection">
						<div>
							<label for="plan-group-id">Plan Group</label>
							<span class="required">*</span>
							<v-select id="plan-group-id" v-model="planGroupId" :options="formatPlanGroupListForDropdown()" :reduce="planGroup => planGroup.id" :selectable="planGroup => (planGroup.id !== null)" :clearable="false">
								<template v-slot:no-options>No matching plan groups found.</template>
							</v-select>
						</div>
					</div>
					<div id="available-plans">
						<div v-for="plan in availablePlanForPlanGroup" class="plan" :class="{'selected': (selectedPlan !== null && selectedPlan.id == plan.id)}" @click="selectedPlan = plan">
							<div class="plan-name">{{plan.plan_name}}</div>
							<div>{{(plan.plan_options.data_allowance === null) ? 'Unlimited' : formatBytes(plan.plan_options.data_allowance)}} Data</div>
							<div v-if="serviceType == 'NBN'">{{formatTransferSpeed(plan.plan_options.download_speed)}} / {{formatTransferSpeed(plan.plan_options.upload_speed)}}</div>
							<div>
								${{Number(plan.monthly_cost).toLocaleString()}} Per Month
								<span v-if="plan.setup_cost > 0" class="setup-cost">(Plus ${{Number(plan.setup_cost).toLocaleString()}} Setup)</span>
							</div>
							<div v-if="plan.contract_months > 0">{{plan.contract_months}} Month Contract</div>
							<div v-else>No Contract</div>
						</div>
					</div>
					<div v-if="currentPlanInactive" v-show="selectedPlan.id != service.plan_details.plan_id" class="inactive-plan-warning">The previous plan for this service is no longer available, so if a new plan is selected, the change can't be reverted.</div>
				</template>
				<div v-else class="critical-error">{{(serviceType == 'NBN') ? 'There are no services available for the selected address.' : 'There are no plans available for the selected service type.'}}</div>
			</template>
			<div v-else-if="displayPlansLoadingIndicator" class="loading-indicator">
				<img src="@/assets/images/loading.gif">
			</div>
			<form v-if="selectedPlan !== null || !displayPlans" class="data-form" @submit.prevent="saveService">
				<div>
					<label for="service-name">Service Name</label>
					<span class="required">*</span>
					<input type="text" id="service-name" v-model="serviceName" @input="errorMessage = null" maxlength="255" />
				</div>
				<div v-if="creatingService" v-show="serviceType == 'NBN'">
					<div :class="{'double-select': (infrastructureType == 'existing')}">
						<label for="infrastructure-type">Connection Type</label>
						<span class="required">*</span>
						<select id="infrastructure-type" v-model="infrastructureType" :disabled="availableInstallationOptions === null || availableInstallationOptions.new_infrastructure_options === null || availableInstallationOptions.existing_infrastructure_options === null" @change="errorMessage = null">
							<option value="" disabled>Select...</option>
							<option value="new">New NCD</option>
							<option value="existing">Existing NCD</option>
						</select>
						<select v-if="infrastructureType == 'existing'" v-model="connectionType" :disabled="availableInstallationOptions.existing_infrastructure_options.currently_inactive === null || availableInstallationOptions.existing_infrastructure_options.currently_active === null" @change="errorMessage = null">
							<option value="" disabled>Select...</option>
							<option value="new">New Service</option>
							<option value="transfer">Transfer Service</option>
						</select>
					</div>
					<div v-if="infrastructureType == 'existing' && connectionType != ''">
						<label for="infrastructure-id">Select NCD</label>
						<span class="required">*</span>
						<select id="infrastructure-id" v-model="infrastructureId" :disabled="availableInfrastructureOptions.length == 1" @change="errorMessage = null">
							<option value="" disabled>Select...</option>
							<option v-for="infrastructureOption in availableInfrastructureOptions" :value="infrastructureOption.id">{{infrastructureOption.display_name}}</option>
						</select>
					</div>
					<div v-if="infrastructureType == 'new' || infrastructureId != ''">
						<label for="installation-type">Installation Type</label>
						<span class="required">*</span>
						<select id="installation-type" v-model="installationType" :disabled="!availableInstallationTypes.includes('professional-installation') || !availableInstallationTypes.includes('customer-device')" @change="errorMessage = null">
							<option value="" disabled>Select...</option>
							<option value="professional-installation">Professional Installation</option>
							<option value="self-installation" disabled>Self Installation</option>
							<option value="customer-device">Customer Device</option>
						</select>
					</div>
				</div>
				<div class="button-wrapper">
					<button type="submit">{{creatingService ? 'Create' : 'Save'}} Service</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import ServiceQualification from '@/views/ServiceQualification';
	import vSelect from 'vue-select';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				service: null,
				serviceType: '',
				serviceQualificationId: null,
				availablePlans: null,
				selectedPlan: null,
				planGroupId: null,
				currentPlanInactive: false,
				availableInstallationOptions: null,
				serviceName: '',
				infrastructureType: '',
				connectionType: '',
				infrastructureId: '',
				installationType: '',
				savingService: false,
				serviceSaved: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			creatingService() { // Used to determine whether a new service is being created, or an existing service is being updated.
				return (this.serviceId === null);
			},
			loadingService() { // Used to determine whether the updated service details are being loaded from the API.
				return (!this.creatingService && (this.service === null || (this.displayPlans && this.availablePlans === null)));
			},
			displayForm() { // Used to determine whether the edit form should be displayed.
				return (!this.dataError && !this.loadingService && !this.serviceSaved);
			},
			displayPlans() { // Used to determine whether to display the plan selection. This is hidden when editing an existing service that's in the provisioning status.
				return (this.creatingService || this.service.status != 'provisioning');
			},
			displayPlansLoadingIndicator() { // Used to determine whether to display the a loading indicator if the list of available plans isn't loaded. This needs to be displayed during service creation when a service type other than NBN is selected.
				return (this.creatingService && this.serviceType != '' && this.serviceType != 'NBN');
			},
			planGroups() { // Returns an array of unique plan groups from the plans in the availablePlans property.
				const planGroups = this.availablePlans.map(plan => JSON.stringify(plan.plan_group)); // Using JSON.stringify and JSON.parse is necessary to correctly identify the planGroup objects as duplicates.
				return [...new Set(planGroups)].map(planGroup => JSON.parse(planGroup));
			},
			displayPlanGroupSelection() { // Used to determine whether to display the plan group dropdown menu. This is only necessary during service creation, when there are more than one available plan group.
				return (this.creatingService && this.planGroups.length > 1);
			},
			availablePlanForPlanGroup() { // Returns the data from the availablePlans property, filtered to only include plans from the selected plan group.
				if(this.displayPlanGroupSelection) {
					const availablePlans = JSON.parse(JSON.stringify(this.availablePlans)); // Makes a copy of the availablePlans array, since the filter method modifies the original array in place.
					return availablePlans.filter(plan => plan.plan_group.group_id == this.planGroupId);
				}
				
				return this.availablePlans; // If there is only one plan group to select from, this is done implicitly, so we just need to return the full plan list.
			},
			apiEndpoint() { // Convenience property to get the API endpoint for accessing services for the customer that is being managed.
				return `customers/${this.managingCustomer.id}/services`;
			},
			availableInfrastructureOptions() { // Used to obtain the list of available existing infrastructure options for the selected connection type.
				const category = (this.connectionType == 'new') ? 'currently_inactive' : 'currently_active';
				return this.availableInstallationOptions.existing_infrastructure_options[category];
			},
			availableInstallationTypes() { // Used to obtain the list of available installation types for the selected infrastructure type / infrastructure option.
				switch(this.infrastructureType) {
					case 'new':
						return this.availableInstallationOptions.new_infrastructure_options;
					case 'existing':
						if(this.connectionType != '') {
							const selectedInfrastructureOption = this.availableInfrastructureOptions.find(option => option.id == this.infrastructureId);
							return (typeof selectedInfrastructureOption !== 'undefined') ? selectedInfrastructureOption.installation_options : null;
						}
					default: // Falls through if no connection type is selected.
						return null;
				}
			},
			...mapGetters(['managingCustomer'])
		},
		components: {
			ModalView, ServiceQualification, vSelect
		},
		async created() { // When the modal is loaded, check if we are editing an existing service, and if so, populate the form with the existing service details.
			if(!this.creatingService) {
				await this.getServiceDetails();
			}
		},
		methods: {
			addressSelected(data = null) { // Called when a service qualification request is completed.
				if(data !== null) {
					this.serviceQualificationId = data.service_qualification_id;
					this.availablePlans = data.plans;
					this.availableInstallationOptions = data.installation_options;
				} else { // When an address is selected, but before the service qualification is completed, clear the list of available plans that is displayed.
					this.availablePlans = null;
					this.availableInstallationOptions = null;
				}
			},
			async getServiceDetails() { // Performs the API request to get the service details for the given service.
				try {
					// Perform the API request to get the service details for the given service.
					const response = await this.HTTP.get(`${this.apiEndpoint}/${this.serviceId}?details=basic`);
					this.service = response.data.data;
					
					// Set the service name in the form, and get the list of available plans for the given service.
					this.serviceName = this.service.service_name;
					this.serviceType = this.service.service_type;
					if(this.service.status != 'provisioning') { // The plan can't be changed for services in the provisioning status, so there is no need to get the plan list in that case.
						await this.getAvailablePlans();
					}
				} catch(error) { // If there was an error obtaining the service details, display the generic error message.
					this.dataError = true;
				}
			},
			async getAvailablePlans() { // Performs the API request to get the list of available plans for the given service.
				try {
					// Perform the API request to get the list of available plans for the given service.
					const providerFilter = !this.creatingService ? `&provider=${this.service.provider}` : ''; // When editing an existing service, we can only switch to plans from the same provider.
					const response = await this.HTTP.get(`plans?customer-type=${this.managingCustomer.customer_type}&service-type=${this.serviceType}&status=active${providerFilter}&limit=1000`);
					let plans = response.data.data;
					
					// For an NBN service, the plan list needs to be further filtered to only include plans matching the technology type of the given service.
					if(this.serviceType == 'NBN') {
						plans = plans.filter(plan => plan.plan_options.technology_types.includes(this.service.technology_type));
					}
					
					// If editing an existing service, search for the selected plan in the list of available plans based on the Plan ID.
					if(!this.creatingService) {
						let selectedPlan = plans.find(plan => plan.id == this.service.plan_details.plan_id);
						if(typeof selectedPlan === 'undefined') { // If the selected plan doesn't exist in the list of available plans, then it must no longer be active, so we need to perform a separate API call to get the plan details.
							selectedPlan = await this.getSelectedPlan();
							plans.unshift(selectedPlan);
							this.currentPlanInactive = true;
						}
						this.selectedPlan = selectedPlan;
						
						// We also need to filter the list of available plans to only include those in the same plan group as the selected plan.
						plans = plans.filter(plan => plan.plan_group.group_id == selectedPlan.plan_group.group_id);
					}
					
					// Set the list of available plans in the component property.
					this.availablePlans = plans;
				} catch(error) { // If there was an error obtaining the plan list, display the generic error message.
					this.dataError = true;
				}
			},
			async getSelectedPlan() { // Performs the API request to get the plan details for the current plan of the given service.
				const response = await this.HTTP.get(`plans/${this.service.plan_details.plan_id}`);
				return response.data.data;
			},
			async saveService() { // Performs the API request to create or update the given service.
				if(this.validateForm()) {
					try {
						// Replace the edit service form with a loading indicator.
						this.savingService = true;
						
						// Set the data to create or update the service.
						const data = {service_name: this.serviceName};
						if(this.selectedPlan !== null) { // A plan isn't selected when editing a service that's in the provisioning status. The modal logic will ensure that if a plan is required, it will always be selecting before submitting the form.
							data.plan_id = this.selectedPlan.id
						}
						
						// When creating a new service, a number of extra fields are required.
						if(this.creatingService) {
							data.service_type = this.serviceType;
							if(this.serviceType == 'NBN') {
								data.service_qualification_id = this.serviceQualificationId;
								data.connection_type = this.infrastructureType;
								data.installation_type = this.installationType;
								if(this.infrastructureType == 'existing') {
									data.infrastructure_id = this.infrastructureId;
								}
							}
						}
						
						// Perform the API request to create or update the given service.
						this.creatingService ? await this.createService(data) : await this.updateService(data);
						
						// If the service was updated successfully, display the success message and instruct the parent component to reload the service list.
						this.serviceSaved = true;
						this.$emit('completed');
					} catch(error) { // If there was an error saving the service, display an error message below the edit service form.
						if(error.response && error.response.status == 400 && error.response.data && (error.response.data.error == "Services can't be created for cancelled customers." || error.response.data.error == "Services can't be edited for cancelled customers." || error.response.data.error == "Cancelled services can't be edited." || error.response.data.error == "The given customer doesn't have a primary user assigned yet.")) { // For these specific errors, display the error message from the API response.
							this.errorMessage = error.response.data.error;
						} else { // For any other error, display a generic error message.
							this.errorMessage = 'An error has occurred.';
						}
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.savingService = false;
					}
				}
			},
			async createService(data) { // Performs the API request to create a service with the given data.
				await this.HTTP.post(this.apiEndpoint, data);
			},
			async updateService(data) { // Performs the API request to update the given service with the given data.
				await this.HTTP.put(`${this.apiEndpoint}/${this.service.id}`, data);
			},
			validateForm() { // Validates the data provided in the form.
				// Set the list of required fields.
				const requiredFields = {'service name': this.serviceName};
				if(this.creatingService && this.serviceType == 'NBN') { // During service creation for NBN services, there are additional fields that are required..
					requiredFields['connection type'] = this.infrastructureType;
					if(this.infrastructureType == 'existing') {
						requiredFields['service type'] = this.connectionType;
						requiredFields['NCD'] = this.infrastructureId;
					}
					requiredFields['installation type'] = this.installationType;
				}
				
				// Ensure that all required fields in the edit form have been filled in.
				for(const field in requiredFields) {
					const value = requiredFields[field];
					if(value == '') {
						this.errorMessage = `The ${field} is required.`;
						return false;
					}
				}
				
				return true;
			},
			setInstallationType() { // Sets the selected installation type when the list of available installation types changes. If only one installation type is available, automatically selects it, otherwise de-selects any existing value.
				this.installationType = '';
				if(this.availableInstallationTypes !== null) {
					if(this.availableInstallationTypes.includes('customer-device') && !this.availableInstallationTypes.includes('professional-installation')) {
						this.installationType = 'customer-device';
					} else if(this.availableInstallationTypes.includes('professional-installation') && !this.availableInstallationTypes.includes('customer-device')) {
						this.installationType = 'professional-installation';
					}
				}
			},
			formatBytes(bytes) { // Converts a number of bytes to a human readable size.
				// Determine the unit of the given bytes value based on the number of powers of 1024.
				const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				const unit = Math.floor(Math.log(bytes) / Math.log(1024));
				
				// Convert the bytes value to the given unit, and return a string with the new value and the unit.
				const value = (bytes / Math.pow(1024, unit)).toFixed(0);
				return `${value} ${units[unit]}`;
			},
			formatTransferSpeed(bps) { // Converts a number of bits per second to a human readable transfer speed.
				// Determine the unit of the given bps value based on the number of powers of 1000.
				const units = ['bps', 'kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
				const unit = Math.floor(Math.log(bps) / Math.log(1000));
				
				// Convert the bps value to the given unit, and return a string with the new value and the unit.
				const value = (bps / Math.pow(1000, unit)).toFixed(0);
				return `${value} ${units[unit]}`;
			},
			formatPlanGroupListForDropdown() { // Returns the list of plan groups formatted for use in the plan group list dropdown menu.
				const planGroupList = this.planGroups.map(function(planGroup){
					return {id: planGroup.group_id, label: planGroup.group_name};
				});
				planGroupList.unshift({id: null, label: 'Select...'});
				return planGroupList;
			},
		},
		watch: {
			availablePlans: function() { // When the list of available plans changes due to a service qualification being performed, clear the selected plan if one was selected, and clear any values that were entered into the service creation form.
				if(this.creatingService) { // The list of available plans changing when a service being created means that it was due to a service qualification being performed (or a service type was selected that doesn't require a service qualification). When updating an existing service, the list of available plans will only change once when they're loaded from the API, so the refresh isn't necessary.
					this.selectedPlan = null;
					this.planGroupId = null;
					this.serviceName = '';
					this.infrastructureType = '';
					this.connectionType = '';
					this.infrastructureId = '';
					this.installationType = '';
					this.errorMessage = null;
				}
			},
			availableInstallationOptions: function(value) { // When the list of available infrastructure options changes due to a service qualification being performed, check if only one infrastructure type (new/existing) is available, and if so, automatically select it.
				if(value !== null) {
					if(value.new_infrastructure_options === null && value.existing_infrastructure_options !== null) {
						this.infrastructureType = 'existing';
					} else if(value.existing_infrastructure_options === null && value.new_infrastructure_options !== null) {
						this.infrastructureType = 'new';
					}
				}
			},
			serviceType: function(value) { // When a service type is selected during service creation, load the list of available plans (except for NBN services, which require a service qualification to be performed first).
				if(this.creatingService) {
					this.availablePlans = null; // Always clear the list of plans that was already displayed, since these are now invalid for the new service type.
					if(value != 'NBN') {
						this.getAvailablePlans();
					}
				}
			},
			infrastructureType: function(value) { // When an infrastructure type is selected, clear the installation type that was previously selected, as the available options may change.
				this.setInstallationType();
				if(value == 'existing') { // If the existing infrastructure type was selected, also check if only one connection type (new/transfer) is available, and if so, automatically select it.
					if(this.availableInstallationOptions.existing_infrastructure_options.currently_active === null && this.availableInstallationOptions.existing_infrastructure_options.currently_inactive !== null) {
						this.connectionType = 'new';
					} else if(this.availableInstallationOptions.existing_infrastructure_options.currently_inactive === null && this.availableInstallationOptions.existing_infrastructure_options.currently_active !== null) {
						this.connectionType = 'transfer';
					}
				}
			},
			connectionType: function(value) { // When a connection type is selected, clear the Infrastructure ID that was previously selected, as the available options will change.
				this.infrastructureId = '';
				if(value != '' && this.availableInfrastructureOptions.length == 1) { // If a non-empty value was selected, and there is only one infrastructure option available for the given value, automatically select it.
					this.infrastructureId = this.availableInfrastructureOptions[0].id;
				}
			},
			infrastructureId: function() { // When an Infrastructure ID is selected, clear the installation type that was previously selected, as the available options may change.
				this.setInstallationType();
			},
			planGroupId() { // When a plan group is selected, clear the previously selected plan.
				this.selectedPlan = null;
				this.errorMessage = null; // Since this method is called when the value of a dropdown menu is changed, we need to clear any error messages, just like we do for any other field.
			}
		}
	}
</script>

<style scoped lang="scss">
	h3 {
		text-align:center;
		margin-top:30px;
		margin-bottom:5px;
	}
	
	#plan-group-wrapper {
		margin-top:0;
	}
	
	#available-plans {
		display:flex;
		flex-wrap:wrap;
		
		.plan {
			border:1px solid #000000;
			background-color:var(--highlight-color-light);
			padding:10px;
			margin:10px;
			flex-shrink:1;
			min-width:250px;
			text-align:center;
			cursor:pointer;
			
			.plan-name {
				font-weight:bold;
			}
			
			.setup-cost {
				font-style:italic;
			}
			
			&.selected {
				outline:4px solid #000000;
				background-color:var(--highlight-color-dark);
			}
		}
	}
	
	.inactive-plan-warning {
		color:#FF0000;
		font-weight:bold;
	}
	
	.data-form {
		margin-top:30px;
		width:50%;
		
		input, select {
			width:100%;
		}
		
		.double-select select:first-of-type {
			border-bottom:none;
		}
	}
</style>