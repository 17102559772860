<template>
	<modal-view title="Change Mobile Number" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="changingMobileNumber || loadingService || loadingAvailableMobileNumbers" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="requestCompleted" class="success-message">The request to change the mobile number of the given service has been sent successfully.</div>
		<div v-else>
			<form v-if="isActiveService && availableMobileNumbers.length > 0" class="data-form" @submit.prevent="changeMobileNumber">
				<div class="checkboxes">
					<label v-for="mobileNumber in availableMobileNumbers">
						<input type="radio" :value="mobileNumber.id" v-model="selectedMobileNumber" />
						<phone-display :value="mobileNumber.mobile_number" @click="selectedMobileNumber = mobileNumber.id"></phone-display>
					</label>
				</div>
				<div class="button-wrapper">
					<button type="submit">Change Mobile Number</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import PhoneDisplay from '@/components/PhoneNumbers/PhoneDisplay';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				service: null,
				selectedMobileNumber: null,
				availableMobileNumbers: null,
				changingMobileNumber: false,
				requestCompleted: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			loadingService() { // Used to determine whether the updated service details are being loaded from the API.
				return (this.service === null);
			},
			loadingAvailableMobileNumbers() { // Used to determine whether a list of available mobile numbers is being loaded from the API.
				return (this.availableMobileNumbers === null);
			},
			isActiveService() { // Used to determine whether the given service is active.
				return ['active', 'suspended'].includes(this.service.status);
			},
			apiEndpoint() { // Convenience property to get the API endpoint for managing the given service.
				return `customers/${this.managingCustomer.id}/services/${this.serviceId}`;
			},
			...mapGetters(['managingCustomer'])
		},
		components: {
			ModalView, PhoneDisplay
		},
		async created() { // When the modal is loaded, load the updated service details from the API.
			await this.getServiceDetails();
		},
		methods: {
			async getServiceDetails() { // Performs the API request to get the service details for the given service.
				try {
					// Perform the API request to get the service details for the given service.
					const response = await this.HTTP.get(this.apiEndpoint);
					this.service = response.data.data;
					
					// Check if the given service is active, and if not, display an error message.
					if(!this.isActiveService) {
						this.errorMessage = "The selected service isn't active.";
						this.availableMobileNumbers = []; // Since the non-generic error message is actually in the form, we need to set this to a non-NULL value to hide the loading indicator.
						return;
					}
					
					// Load the list of available mobile numbers for the given service.
					await this.getAvailableMobileNumbers();
				} catch(error) { // If there was an error obtaining the service details, display the generic error message.
					this.dataError = true;
				}
			},
			async getAvailableMobileNumbers() { // Performs the API request to get a list of available mobile numbers.
				try {
					const response = await this.HTTP.get(`${this.apiEndpoint}/mobile-number`);
					this.availableMobileNumbers = response.data.data;
				} catch(error) { // If there was an error obtaining the list of available mobile numbers, display an error message.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "A mobile number change request is already in progress for the given service.") { // If the error is that there is already a mobile number change request in progress for the given service, display the specific error message from the API response.
						this.errorMessage = error.response.data.error;
						this.availableMobileNumbers = []; // Since the non-generic error message is actually in the form, we need to set this to a non-NULL value to hide the loading indicator.
					} else { // For any other error, display the generic error message.
						this.dataError = true;
					}
				}
			},
			async changeMobileNumber() { // Performs the API request to change the mobile number for the given service.
				if(this.validateForm()) {
					try {
						// Replace the form with a loading indicator.
						this.changingMobileNumber = true;
						
						// Perform the API request to change the mobile number for given service.
						const data = {mobile_number_id: this.selectedMobileNumber};
						await this.HTTP.post(`${this.apiEndpoint}/mobile-number`, data);
						
						// If the request was successful, display the success message.
						this.requestCompleted = true;
					} catch(error) { // If there was an error changing the mobile number of the service, display an error message below the form.
						this.errorMessage = 'An error has occurred.';
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.changingMobileNumber = false;
					}
				}
			},
			validateForm() { // Validates that a mobile number has been selected.
				if(this.selectedMobileNumber === null) {
					this.errorMessage = 'A mobile number must be selected.';
					return false;
				}
				
				return true;
			}
		},
		watch: {
			selectedMobileNumber() { // When a mobile number is selected, we need to clear any error messages, just like we do for any other field. Placing this directly in the change event of the radio buttons means it doesn't take effect when clicking the label.
				this.errorMessage = null;
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		.checkboxes label {
			display:inline-block;
		}
		
		.phone-display {
			display:inline-block;
			margin-left:5px;
			font-size:1.1rem;
			
			&:deep(div) {
				display:none;
			}
			
			&:deep(input) {
				cursor:pointer;
			}
		}
	}
</style>