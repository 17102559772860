<template>
	<modal-view title="Edit Shipping Details" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="savingService || loadingService" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="serviceSaved" class="success-message">Shipping Details Updated Successfully</div>
		<div v-else>
			<form class="data-form" @submit.prevent="saveService">
				<div>
					<label for="sim-card-number">SIM Card Number</label>
					<span class="required">*</span>
					<input type="text" id="sim-card-number" v-model="simCardNumber" @input="errorMessage = null" maxlength="255" />
				</div>
				<div>
					<label for="tracking-number">Tracking Number</label>
					<span class="required">*</span>
					<input type="text" id="tracking-number" v-model="trackingNumber" @input="errorMessage = null" maxlength="255" />
				</div>
				<div class="button-wrapper">
					<button type="submit">Save Shipping Details</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				service: null,
				simCardNumber: '',
				trackingNumber: '',
				savingService: false,
				serviceSaved: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			loadingService() { // Used to determine whether the updated service details are being loaded from the API.
				return (this.service === null);
			}
		},
		components: {
			ModalView
		},
		async created() { // When the modal is loaded, get the shipping details for the given service.
			await this.getServiceDetails();
		},
		methods: {
			async getServiceDetails() { // Performs the API request to get the shipping details for the given service.
				try {
					const response = await this.HTTP.get(`pending-services/${this.serviceId}`);
					this.service = response.data.data;
					
					this.simCardNumber = this.service.sim_card_number;
					this.trackingNumber = this.service.tracking_number;
				} catch(error) { // If there was an error obtaining the shipping details, display the generic error message.
					this.dataError = true;
				}
			},
			async saveService() { // Performs the API request to update the given service.
				try {
					// Replace the edit shipping details form with a loading indicator.
					this.savingService = true;
					
					// Perform the API request to update the shipping details given service.
					const data = {sim_card_number: this.simCardNumber, tracking_number: this.trackingNumber};
					await this.HTTP.put(`pending-services/${this.service.id}`, data);
					
					// If the shipping details were updated successfully, display the success message and instruct the parent component to reload the service list.
					this.serviceSaved = true;
					this.$emit('completed');
				} catch(error) { // If there was an error saving the shipping details, display an error message below the edit shipping details form.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The SIM Card Number provided is invalid.") { // If the error is that the given sim card number is invalid, display the error message from the API response.
						this.errorMessage = error.response.data.error;
					} else { // For any other error, display a generic error message.
						this.errorMessage = 'An error has occurred.';
					}
				} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
					this.savingService = false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		input {
			width:100%;
		}
	}
</style>