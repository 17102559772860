<template>
	<modal-view title="Activate Service" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="activatingService || loadingService" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="serviceActivated" class="success-message">Service Activated Successfully</div>
		<div v-else>
			<form v-if="isPendingService && this.service.sim_card_number !== null" class="data-form" @submit.prevent="activateService">
				<div>
					<label for="activation-type">Activation Type</label>
					<span class="required">*</span>
					<select id="activation-type" v-model="activationType" @change="errorMessage = null">
						<option value="" disabled>Select...</option>
						<option value="new">New Mobile Number</option>
						<option value="port">Port Existing Mobile Number</option>
					</select>
				</div>
				<template v-if="activationType == 'new'">
					<div v-if="loadingAvailableMobileNumbers" class="loading-indicator">
						<img src="@/assets/images/loading.gif">
					</div>
					<div v-else class="checkboxes">
						<label class="checkboxes-label">Mobile Number</label>
						<label v-for="mobileNumber in availableMobileNumbers">
							<input type="radio" :value="mobileNumber.id" v-model="selectedMobileNumber" />
							<phone-display :value="mobileNumber.mobile_number" @click="selectedMobileNumber = mobileNumber.id"></phone-display>
						</label>
					</div>
				</template>
				<template v-else-if="activationType == 'port'">
					<div>
						<label for="port-type">Previous Plan Type</label>
						<span class="required">*</span>
						<select id="port-type" v-model="portType" @change="errorMessage = null">
							<option value="" disabled>Select...</option>
							<option value="prepaid">Prepaid</option>
							<option value="postpaid">Postpaid</option>
						</select>
					</div>
					<div v-show="portType == 'prepaid'">
						<label for="port-type">Date of Birth</label>
						<span class="required">*</span>
						<date-picker v-model:value="portDob" format="D-MM-YYYY" title-format="D-MM-YYYY" value-type="YYYY-MM-DD" :input-attr="{id: 'dob'}" :clearable="false" @change="errorMessage = null" />
					</div>
					<div v-show="portType == 'postpaid'">
						<label for="port-account-number">Previous Account Number</label>
						<span class="required">*</span>
						<input type="text" id="port-account-number" v-model="portAccountNumber" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="port-mobile-number">Mobile Number</label>
						<span class="required">*</span>
						<phone-input id="port-mobile-number" v-model="portMobileNumber" :disabled="verificationCodeSent" @input="errorMessage = null"></phone-input>
						<div v-show="serviceVerified" id="verification-success-message" class="success-message">Verified</div>
					</div>
					<div v-if="!serviceVerified">
						<label for="verification-code">Verification Code</label>
						<span class="required">*</span>
						<div v-if="verifyingService" class="loading-indicator">
							<img src="@/assets/images/loading.gif">
						</div>
						<div v-else id="verification-code-wrapper" :class="{sent: verificationCodeSent}">
							<template v-if="verificationCodeSent">
								<input type="text" id="verification-code" v-model="verificationCode" @input="errorMessage = null" />
								<button type="button" @click="validateVerificationCode">Verify</button>
							</template>
							<button type="button" @click="sendVerificationCode">{{verificationCodeSent ? 'Resend' : 'Send Verification Code'}}</button>
						</div>
					</div>
				</template>
				<div v-show="allowFormSubmission" class="button-wrapper">
					<button type="submit">Activate Service</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import PhoneDisplay from '@/components/PhoneNumbers/PhoneDisplay';
	import PhoneInput from '@/components/PhoneNumbers/PhoneInput';
	import DatePicker from 'vue-datepicker-next';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				service: null,
				activationType: '',
				portType: '',
				portAccountNumber: '',
				portDob: '',
				portMobileNumber: '',
				selectedMobileNumber: null,
				availableMobileNumbers: null,
				verificationId: null,
				verificationCode: '',
				verifyingService: false,
				serviceVerified: false,
				activatingService: false,
				serviceActivated: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			loadingService() { // Used to determine whether the updated service details are being loaded from the API.
				return (this.service === null);
			},
			loadingAvailableMobileNumbers() { // Used to determine whether a list of available mobile numbers is being loaded from the API.
				return (this.availableMobileNumbers === null || this.availableMobileNumbers.length == 0);
			},
			isPendingService() { // Used to determine whether the given service is in the pending status.
				return ['pending', 'shipped'].includes(this.service.status);
			},
			verificationCodeSent() { // Used to determine whether a verification code has been sent.
				return (this.verificationId !== null);
			},
			allowFormSubmission() { // Used to determine whether to allow the form to be submitted. This isn't allowed when porting an existing mobile number until the verification process has been completed.
				return (this.activationType == 'new' || this.serviceVerified);
			},
			apiEndpoint() { // Convenience property to get the API endpoint for managing the given service.
				return `customers/${this.managingCustomer.id}/services/${this.serviceId}`;
			},
			...mapGetters(['managingCustomer'])
		},
		components: {
			ModalView, PhoneDisplay, PhoneInput, DatePicker
		},
		async created() { // When the modal is loaded, load the updated service details from the API.
			await this.getServiceDetails();
		},
		methods: {
			async getServiceDetails() { // Performs the API request to get the service details for the given service.
				try {
					// Perform the API request to get the service details for the given service.
					const response = await this.HTTP.get(this.apiEndpoint);
					this.service = response.data.data;
					
					// Check if the given service is in the pending status and has a Sim Card Number assigned, and if not, display an error message.
					if(!this.isPendingService) {
						this.errorMessage = "The selected service isn't in the pending status.";
					} else if(this.service.sim_card_number === null) {
						this.errorMessage = "The selected service hasn't been assigned a SIM Card Number.";
					}
				} catch(error) { // If there was an error obtaining the service details, display the generic error message.
					this.dataError = true;
				}
			},
			async getAvailableMobileNumbers() { // Performs the API request to get a list of available mobile numbers.
				try {
					const response = await this.HTTP.get(`${this.apiEndpoint}/activate`);
					this.availableMobileNumbers = response.data.data;
				} catch(error) { // If there was an error obtaining the list of available mobile numbers, display the generic error message.
					this.dataError = true;
				}
			},
			async sendVerificationCode() { // Performs the API request to send a verification code.
				if(this.validateForm(true)) {
					try {
						// Replace the verification code section of the form with a loading indicator.
						this.verifyingService = true;
						
						// Perform the API request to send a verification code, and set the Verification ID to the one from the response.
						const response = await this.HTTP.get(`verification-code?phone-number=${this.portMobileNumber}`);
						this.verificationId = response.data.data.verification_id;
					} catch(error) { // If there was an error sending the verification code, display an error message.
						this.errorMessage = 'An error has occurred.';
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.verifyingService = false;
					}
				}
			},
			async validateVerificationCode() { // Performs the API request to validate a verification code.
				// Validate whether a verification code has been entered.
				if(this.verificationCode == '') {
					this.errorMessage = `The verification code is required.`;
					return;
				}
				
				try {
					// Replace the verification code section of the form with a loading indicator.
					this.verifyingService = true;
					
					// Perform the API request to send a verification code.
					const data = {verification_id: this.verificationId, verification_code: this.verificationCode};
					await this.HTTP.post('verification-code', data);
					
					// If the verification code was validated successfully, enable the form to be submitted.
					this.serviceVerified = true;
				} catch(error) { // If there was an error sending the verification code, display an error message.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error != 'The Verification ID provided is invalid.') { // A 400 status code indicates a validation error, so we should display the error returned by the API (except for an invalid Verification ID, which is an internal error).
						this.errorMessage = error.response.data.error;
					} else { // For any other error, display a generic error message.
						this.errorMessage = 'An error has occurred.';
					}
				} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
					this.verifyingService = false;
				}
			},
			async activateService() { // Performs the API request to activate the given service.
				if(this.allowFormSubmission && this.validateForm()) {
					try {
						// Replace the activation form with a loading indicator.
						this.activatingService = true;
						
						// Perform the API request to activate given service.
						const data = this.setActivationData();
						await this.HTTP.post(`${this.apiEndpoint}/activate`, data);
						
						// If the service was activated successfully, display the success message and instruct the parent component to reload the service list.
						this.serviceActivated = true;
						this.$emit('completed');
					} catch(error) { // If there was an error activating the service, display an error message.
						if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The suburb and post code configured for the selected customer are invalid.") { // If the error is that the customer's address is invalid, display the specific error message from the API response.
							this.errorMessage = error.response.data.error;
						} else { // For any other error, display a generic error message.
							this.errorMessage = 'An error has occurred.';
						}
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.activatingService = false;
					}
				}
			},
			setActivationData() { // Returns an object to use in the API requests for activating the given service.
				const data = {};
				switch(this.activationType) { // Set the data to activate the service, based on the activation type.
					case 'new':
						data.mobile_number_id = this.selectedMobileNumber;
						break;
					case 'port':
						data.port_type = this.portType;
						data.port_mobile_number = this.portMobileNumber;
						switch(this.portType) { // Set the additional field required to activate the service, based on the port type.
							case 'prepaid':
								data.port_dob = this.portDob;
								break;
							case 'postpaid':
								data.port_account_number = this.portAccountNumber;
								break;
						}
						break;
				}
				
				return data;
			},
			validateForm(sendingVerificationCode = false) { // Validates the data provided in the form.
				// Set the list of required fields.
				const requiredFields = {'activation type': this.activationType};
				if(this.activationType == 'port') {
					if(!sendingVerificationCode) { // When sending the verification code, only the port mobile number is required.
						requiredFields['previous plan type'] = this.portType;
						switch(this.portType) {
							case 'prepaid':
								requiredFields['date of birth'] = this.portDob;
								break;
							case 'postpaid':
								requiredFields['previous account number'] = this.portAccountNumber;
								break;
						}
					}
					
					requiredFields['mobile number to port'] = this.portMobileNumber;
				}
				
				// Ensure that all required fields in the activation form have been filled in.
				for(const field in requiredFields) {
					const value = requiredFields[field];
					if(value == '' && value !== false) { // For the mobile number field, if the value entered is invalid, it's returned as FALSE. This is validated separately, so we want to ignore it here.
						this.errorMessage = `The ${field} is required.`;
						return false;
					}
				}
				
				// Validate the phone number selection, depending on the activation type.
				if(this.activationType == 'new' && this.selectedMobileNumber === null) { // If the new mobile number option is selected, ensure that a mobile number has been selected.
					this.errorMessage = 'A mobile number must be selected.';
					return false;
				} else if(this.activationType == 'port' && this.portMobileNumber === false) { // If the port mobile number option is selected, validate that the mobile number field is valid.
					this.errorMessage = "The mobile number provided is invalid.";
					return false;
				}
				
				return true;
			}
		},
		watch: {
			async activationType(value) { // When the activation type is set to 'new', load a list of available mobile numbers from the API (if it hasn't already been loaded).
				if(value == 'new' && this.availableMobileNumbers === null) {
					this.availableMobileNumbers = []; // This prevents the API call from being performed multiple times if the selection is changed again while the request is in progress.
					await this.getAvailableMobileNumbers();
				}
			},
			selectedMobileNumber() { // When a mobile number is selected, we need to clear any error messages, just like we do for any other field. Placing this directly in the change event of the radio buttons means it doesn't take effect when clicking the label.
				this.errorMessage = null;
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		input, select, .mx-datepicker {
			width:100%;
		}
		
		.mx-datepicker {
			display:block;
		}
		
		.checkboxes-label {
			margin-bottom:10px;
		}
		
		.checkboxes label:not(.checkboxes-label) {
			display:inline-block;
		}
		
		.phone-display {
			display:inline-block;
			margin-left:5px;
			font-size:1.1rem;
			
			&:deep(div) {
				display:none;
			}
			
			&:deep(input) {
				cursor:pointer;
			}
		}
	}
	
	#verification-code-wrapper.sent {
		display:flex;
		justify-content:space-between;
		
		input {
			flex-basis:45%;
		}
		
		button {
			flex-basis:25%;
		}
	}
	
	#verification-success-message {
		text-align:left;
		margin-top:5px;
	}
</style>