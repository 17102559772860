<template>
	<modal-view title="Shipping Details" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="loadingService" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else id="service-details-wrapper">
			<div>
				<span class="label">Customer Name</span>
				{{service.customer_address.customer_name}}
			</div>
			<div>
				<span class="label">Address<template v-if="service.customer_address.address_line_2"> Line 1</template></span>
				{{service.customer_address.address_line_1}}
			</div>
			<div v-if="service.customer_address.address_line_2">
				<span class="label">Address Line 2</span>
				{{service.customer_address.address_line_2}}
			</div>
			<div>
				<span class="label">Suburb</span>
				{{service.customer_address.suburb}}
			</div>
			<div>
				<span class="label">State</span>
				{{service.customer_address.state}}
			</div>
			<div>
				<span class="label">Post Code</span>
				{{service.customer_address.post_code}}
			</div>
			<div>
				<span class="label">Country</span>
				{{(service.customer_address.country == 'AU') ? 'Australia' : service.customer_address.country}}
			</div>
		</div>
	</modal-view>
</template>

<script>
import ModalView from '@/components/ModalView';

export default {
	props: {
		serviceId: String
	},
	data() {
		return {
			service: null,
			dataError: false
		}
	},
	computed: {
		loadingService() { // Used to determine whether the service details are being loaded from the API.
			return (this.service === null);
		}
	},
	components: {
		ModalView
	},
	async created() { // When the modal is loaded, get the shipping details for the given service.
		await this.getServiceDetails();
	},
	methods: {
		async getServiceDetails() { // Performs the API request to get the service details for the given service.
			try {
				const response = await this.HTTP.get(`pending-services/${this.serviceId}`);
				this.service = response.data.data;
			} catch(error) { // If there was an error obtaining the shipping details, display the generic error message.
				this.dataError = true;
			}
		}
	}
}
</script>

<style scoped lang="scss">
	#service-details-wrapper div {
		padding:5px 0;
	}
	
	.label {
		font-weight:bold;
		
		&::after {
			content:":";
		}
	}
</style>