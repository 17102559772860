<template>
	<modal-view title="Service Details" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="loadingService" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<template v-else>
			<div id="service-details-wrapper" :class="{'three-columns': threeColumnLayout}">
				<div>
					<h3 v-if="showAddressDetails || showProviderDetails || showShippingDetails">Service Details</h3>
					<div>
						<span class="label">Service ID</span>
						{{service.id}}
					</div>
					<div>
						<span class="label">Service Name</span>
						{{service.service_name}}
					</div>
					<div>
						<span class="label">Service Type</span>
						{{service.service_type}}
					</div>
					<div v-if="service.service_type == 'Mobile'">
						<span class="label">Service Number</span>
						{{service.service_number}}
					</div>
					<div>
						<span class="label">Plan Name</span>
						{{service.plan_details.plan_name}}
					</div>
					<div>
						<span class="label">Data Allowance</span>
						{{(service.plan_details.data_allowance === null) ? 'Unlimited' : formatBytes(service.plan_details.data_allowance)}}
					</div>
					<template v-if="service.service_type == 'NBN'">
						<div>
							<span class="label">Speed</span>
							{{formatTransferSpeed(service.plan_details.download_speed)}} / {{formatTransferSpeed(service.plan_details.upload_speed)}}
						</div>
						<div>
							<span class="label">Technology Type</span>
							{{service.technology_type}}
						</div>
					</template>
					<template v-if="showDetailedInfo">
						<div v-if="isInternalUser && service.contract_dates.order_date">
							<span class="label">Order Date</span>
							{{formatDate(service.contract_dates.order_date)}}
						</div>
						<div v-if="service.contract_dates.activation_date">
							<span class="label">Activation Date</span>
							{{formatDate(service.contract_dates.activation_date)}}
						</div>
						<div v-if="service.contract_dates.contract_end_date">
							<span class="label">Contract End Date</span>
							{{formatDate(service.contract_dates.contract_end_date)}}
						</div>
						<div v-if="service.status == 'disconnected' && service.provider_details.disconnection_date">
							<span class="label">Disconnection Date</span>
							{{formatDate(service.provider_details.disconnection_date)}}
						</div>
					</template>
					<div>
						<span class="label">Status</span>
						{{capitaliseFirstLetter(service.status)}}
					</div>
					<div v-if="isInternalUser && !showProviderDetails">
						<span class="label">Provider</span>
						{{formatProvider(service.provider)}}
					</div>
				</div>
				<div v-if="showAddressDetails">
					<h3>Address Details</h3>
					<div v-if="service.address.address_line_1">
						<span class="label">Address<template v-if="service.address.address_line_2"> Line 1</template></span>
						{{service.address.address_line_1}}
					</div>
					<div v-if="service.address.address_line_2">
						<span class="label">Address Line 2</span>
						{{service.address.address_line_2}}
					</div>
					<div v-if="service.address.suburb">
						<span class="label">Suburb</span>
						{{service.address.suburb}}
					</div>
					<div v-if="service.address.state">
						<span class="label">State</span>
						{{service.address.state}}
					</div>
					<div v-if="service.address.post_code">
						<span class="label">Post Code</span>
						{{service.address.post_code}}
					</div>
					<div v-if="service.address.country">
						<span class="label">Country</span>
						{{(service.address.country == 'AU') ? 'Australia' : service.address.country}}
					</div>
				</div>
				<div v-if="showProviderDetails">
					<h3>Provider Details</h3>
					<div>
						<span class="label">Provider</span>
						{{service.provider}}
					</div>
					<div v-if="service.provider_details.account_id">
						<span class="label">Provider Account ID</span>
						{{service.provider_details.account_id}}
					</div>
					<div v-if="service.provider_details.service_id">
						<span class="label">Provider Service ID</span>
						{{service.provider_details.service_id}}
					</div>
					<div v-if="service.provider_details.order_date">
						<span class="label">Provider Order Date</span>
						{{formatDate(service.provider_details.order_date)}}
					</div>
					<template v-if="service.status == 'provisioning'">
						<div v-if="service.provider_details.order_status">
							<span class="label">Provider Order Status</span>
							{{service.provider_details.order_status}}
						</div>
					</template>
					<template v-else-if="service.service_type == 'NBN'">
						<div v-if="service.provider_details.address_id">
							<span class="label">Provider Address ID</span>
							{{service.provider_details.address_id}}
						</div>
					</template>
				</div>
				<div v-if="showShippingDetails">
					<h3>Shipping Details</h3>
					<div>
						<span class="label">SIM Card Number</span>
						{{service.sim_card_number}}
					</div>
					<div v-if="service.status == 'shipped' && service.tracking_number">
						<span class="label">Tracking Number</span>&nbsp;
						<a :href="`https://auspost.com.au/mypost/track/#/details/${service.tracking_number}`" target="_blank">{{service.tracking_number}}</a>
					</div>
				</div>
			</div>
			<div v-if="showDataUsage" id="data-usage-wrapper">
				<div v-if="loadingDataUsage" class="loading-indicator">
					<img src="@/assets/images/loading.gif">
				</div>
				<template v-else>
					<h3>Data Usage</h3>
					<progress-bar :title="dataUsage['add-on'] ? 'Plan Data' : null" :total-value="dataUsage.standard.total" :remaining-value="dataUsage.standard.remaining" :bytes="true" separator="used out of"></progress-bar>
					<progress-bar v-if="dataUsage['add-on']" title="Data Add-On" :total-value="dataUsage['add-on'].total" :remaining-value="dataUsage['add-on'].remaining" :bytes="true" separator="used out of"></progress-bar>
					<div v-else class="data-add-on-form">
						<div v-if="addingDataAddOn" class="loading-indicator">
							<img src="@/assets/images/loading.gif">
						</div>
						<div v-else-if="dataAddOnError" class="critical-error">An error has occurred.</div>
						<div v-else-if="dataAddOnAdded" class="success-message">Data Add-On Added Successfully.</div>
						<form v-else class="data-form" @submit.prevent="addDataAddOn">
							<button type="submit">Add Data Add-On</button>
						</form>
					</div>
				</template>
			</div>
		</template>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import ProgressBar from '@/components/ProgressBar';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				service: null,
				dataUsage: null,
				dataError: false,
				addingDataAddOn: false,
				dataAddOnAdded: false,
				dataAddOnError: false
			}
		},
		computed: {
			loadingService() { // Used to determine whether the service details are being loaded from the API.
				return (this.service === null);
			},
			loadingDataUsage() { // Used to determine whether the data usage information is being loaded from the API.
				return (this.dataUsage === null);
			},
			showDetailedInfo() { // Used to determine whether to display detailed service information. This is the information that is available after a service becomes active.
				return !['pending', 'shipped', 'provisioning', 'cancelled'].includes(this.service.status);
			},
			showAddressDetails() { // Used to determine whether to display the address details. This is only available for NBN services after the service becomes active.
				return (this.showDetailedInfo && this.service.service_type == 'NBN');
			},
			showProviderDetails() { // Used to determine whether to display the provider details, which are only displayed to internal users, and don't become available until the order is submitted to the provider.
				return (this.isInternalUser && !['pending', 'shipped'].includes(this.service.status));
			},
			showShippingDetails() { // Used to determine whether to display the shipping details, which are only available for mobile services in the pending or shipped statuses.
				return (this.service.service_type == 'Mobile' && ['pending', 'shipped'].includes(this.service.status) && this.service.sim_card_number);
			},
			threeColumnLayout() { // Used to determine whether the service details are displayed in three columns instead of two. This is required for internal users if both the Address Details and Provider Details columns are required.
				return (this.isInternalUser && this.showAddressDetails && this.showProviderDetails);
			},
			showDataUsage() { // Used to determine whether to display data usage information. This is only available for active mobile services.
				return (this.service.service_type == 'Mobile' && ['active', 'suspended'].includes(this.service.status));
			},
			apiEndpoint() { // Convenience property to get the API endpoint for managing the given service.
				return `customers/${this.managingCustomer.id}/services/${this.serviceId}`;
			},
			...mapGetters(['managingCustomer', 'isInternalUser'])
		},
		components: {
			ModalView, ProgressBar
		},
		async created() { // When the modal is loaded, get the service details for the given service.
			await this.getServiceDetails();
		},
		methods: {
			async getServiceDetails() { // Performs the API request to get the service details for the given service.
				try {
					const response = await this.HTTP.get(this.apiEndpoint);
					this.service = response.data.data;
					if(this.showDataUsage) { // If the the data usage should be displayed for the given service, also perform the API request to get the data usage information.
						await this.getDataUsage();
					}
				} catch(error) { // If there was an error obtaining the service details, display the generic error message.
					this.dataError = true;
				}
			},
			async getDataUsage() { // Performs the API request to get the data usage information for the given service.
				try {
					const response = await this.HTTP.get(`${this.apiEndpoint}/usage`);
					this.dataUsage = response.data.data;
				} catch(error) { // If there was an error obtaining the data usage information, display the generic error message.
					this.dataError = true;
				}
			},
			async addDataAddOn() { // Performs the API request add a Data Add-On for given service.
				try {
					this.addingDataAddOn = true; // Replaces the Data Add-On button with a loading indicator.
					await this.HTTP.post(`${this.apiEndpoint}/add-on`);
					this.dataAddOnAdded = true;
				} catch(error) { // If there was an error changing the mobile number of the service, handle it as appropriate depending on the error message.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "An order for a data add-on is already in progress for the given service.") { // If the error is that an add-on has already been ordered, just act as if the request was successful.
						this.dataAddOnAdded = true;
					} else { // For any other error, display an error message in place of the Data Add-On button.
						this.dataAddOnError = true;
					}
				} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
					this.addingDataAddOn = false;
				}
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			formatDate(date) { // Given a date in YYYY-MM-DD format, returns the same date converted to D-MM-YYYY format.
				date = new Date(date + ' UTC');
				const day = date.getDate();
				const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months start at 0, so we need to add 1, then add a leading zero if applicable.
				const year = date.getFullYear();
				
				return `${day}-${month}-${year}`;
			},
			formatProvider(provider) { // Given the provider for a service, returns the user-friendly representation.
				switch(provider) {
					case 'OctaneCorporate':
						return 'Telco in a Box Corporate';
					case 'OctaneCorporateLegacy':
						return 'Telco in a Box Corporate Legacy';
					case 'OctaneResidential':
						return 'Telco in a Box Residential';
					default:
						return provider;
				}
			},
			formatBytes(bytes) { // Converts a number of bytes to a human readable size.
				// Determine the unit of the given bytes value based on the number of powers of 1024.
				const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				const unit = Math.floor(Math.log(bytes) / Math.log(1024));
				
				// Convert the bytes value to the given unit, and return a string with the new value and the unit.
				const value = (bytes / Math.pow(1024, unit)).toFixed(0);
				return `${value} ${units[unit]}`;
			},
			formatTransferSpeed(bps) { // Converts a number of bits per second to a human readable transfer speed.
				// Determine the unit of the given bps value based on the number of powers of 1000.
				const units = ['bps', 'kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
				const unit = Math.floor(Math.log(bps) / Math.log(1000));
				
				// Convert the bps value to the given unit, and return a string with the new value and the unit.
				const value = (bps / Math.pow(1000, unit)).toFixed(0);
				return `${value} ${units[unit]}`;
			}
		}
	}
</script>

<style scoped lang="scss">
	#service-details-wrapper {
		overflow:auto;
			
		&>div {
			width:50%;
			float:left;
			
			div {
				padding:5px 0;
			}
		}
		
		&.three-columns > div {
			width:30%;
		}
	}
	
	#data-usage-wrapper {
		h3 {
			text-align:center;
			margin-bottom:-50px;
		}
		
		.progress-bar {
			margin-top:50px;
		}
		
		.data-add-on-form {
			margin-top:30px;
			
			.data-form {
				width:max-content;
			}
		}
	}
	
	.label {
		font-weight:bold;
		
		&::after {
			content:":";
		}
	}
</style>