<template>
	<selectable-value-cell :record-index="recordIndex" :selectable-values="selectableValues" :selecting-record-index="selectingRecordIndex" :is-selecting="false" :allow-selection="selectionAllowed" @show-selection="display => $emit('show-selection', display)" @save-selection="selectAction">
		<disclosure-button v-if="!selectionAllowed" :disabled="true" :toggle-enabled="false"></disclosure-button>
	</selectable-value-cell>
</template>

<script>
	import SelectableValueCell from '@/components/SelectionCells/SelectableValueCell';
	import DisclosureButton from '@/components/DisclosureButton';
	
	export default {
		props: {
			recordIndex: Number,
			selectableActions: Object,
			selectingRecordIndex: Number,
			allowSelection: {
				type: Boolean,
				default: true
			}
		},
		computed: {
			selectableValues() { // Used to convert the selectableActions property, which contains the action name as the key and the action function as the value, to the selectableValues property expected by the SelectableValueCell component, which requires the action name as the value.
				// Extract just the action names from the selectableActions property, discarding the action functions.
				const values = Object.keys(this.selectableActions);
				
				// Create an object to use for the selectableValues property, using the action names as both the key and the value.
				const selectableValues = {};
				for(const value of values) {
					selectableValues[value] = value;
				}
				
				return selectableValues;
			},
			hasActions() { // Used to determine whether any actions were passed to the selectableActions property.
				return (Object.keys(this.selectableActions).length > 0);
			},
			selectionAllowed() { // Used to determine whether the dropdown menu is active. This requires the allowSelection property to be TRUE, and for there to be at least one action available.
				return (this.allowSelection && this.hasActions);
			}
		},
		components: {
			SelectableValueCell, DisclosureButton
		},
		methods: {
			selectAction(action) { // Calls the action function for the action that was selected from the dropdown menu.
				const actionFunction = this.selectableActions[action];
				actionFunction();
				
				this.$emit('show-selection', false); // Sends an event to the parent component to hide the selection dropdown menu.
			}
		}
	}
</script>

<style scoped lang="scss">
	td, .selection-cell {
		padding:0;
		background-color:#FFA700;
		
		&.show-selection {
			background-color:#FF8700;
		}
		
		&:deep(.disclosure-button) {
			margin:0;
			font-size:3rem;
		}
		
		&:deep(.selection-dropdown) {
			color:var(--main-text-color);
			white-space:nowrap;
			right:auto;
		}
	}
</style>