<template>
	<modal-view title="Replace SIM Card" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="saving || loadingService" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="completed" class="success-message">The request to update the SIM Card Number of the given service has been sent successfully.</div>
		<div v-else>
			<form v-if="isActiveService" class="data-form" @submit.prevent="updateSimCardNumber">
				<div>
					<label for="sim-card-number">New SIM Card Number</label>
					<span class="required">*</span>
					<input type="text" id="sim-card-number" v-model="simCardNumber" @input="errorMessage = null" maxlength="255" />
				</div>
				<div class="button-wrapper">
					<button type="submit">Update SIM Card Number</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				service: null,
				simCardNumber: '',
				saving: false,
				completed: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			loadingService() { // Used to determine whether the updated service details are being loaded from the API.
				return (this.service === null);
			},
			isActiveService() { // Used to determine whether the given service is active.
				return ['active', 'suspended'].includes(this.service.status);
			},
			apiEndpoint() { // Convenience property to get the API endpoint for managing the given service.
				return `customers/${this.managingCustomer.id}/services/${this.serviceId}`;
			},
			...mapGetters(['managingCustomer'])
		},
		components: {
			ModalView
		},
		async created() { // When the modal is loaded, load the updated service details from the API.
			await this.getServiceDetails();
		},
		methods: {
			async getServiceDetails() { // Performs the API request to get the service details for the given service.
				try {
					// Perform the API request to get the service details for the given service.
					const response = await this.HTTP.get(this.apiEndpoint);
					this.service = response.data.data;
					
					// Check if the given service is active, and if not, display an error message.
					if(!this.isActiveService) {
						this.errorMessage = "The selected service isn't active.";
					}
				} catch(error) { // If there was an error obtaining the service details, display the generic error message.
					this.dataError = true;
				}
			},
			async updateSimCardNumber() { // Performs the API request to update the SIM Card Number for the given service.
				if(this.validateForm()) {
					try {
						// Replace the edit shipping details form with a loading indicator.
						this.saving = true;
						
						// Perform the API request to update the SIM Card Number for given service.
						const data = {sim_card_number: this.simCardNumber};
						await this.HTTP.put(`${this.apiEndpoint}/replace-sim-card`, data);
						
						// If the request was successful, display the success message.
						this.completed = true;
					} catch(error) { // If there was an error updating the SIM Card Number, display an error message below the form.
						if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The SIM Card Number provided is invalid.") { // If the error is that the given sim card number is invalid, display the error message from the API response.
							this.errorMessage = error.response.data.error;
						} else { // For any other error, display a generic error message.
							this.errorMessage = 'An error has occurred.';
						}
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.saving = false;
					}
				}
			},
			validateForm() { // Validates that a SIM Card Number was provided.
				if(this.simCardNumber == '') {
					this.errorMessage = 'The SIM Card Number is required.';
					return false;
				}
				
				return true;
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		input {
			width:100%;
		}
	}
</style>