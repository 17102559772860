<template>
	<div class="progress-bar">
		<div>
			<span v-if="title" class="title">{{title}}</span>
			{{formatValue(progressValue)}} {{separator}} {{formatValue(totalValue)}}
		</div>
		<div class="progress-wrapper">
			<div v-if="this.progressPercentage > 0" class="progress" :style="{width: `${this.progressPercentage}%`}"></div>
			<div class="progress-percentage" :class="{'no-data': (this.progressPercentage == 0)}">{{this.progressPercentage}}%</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: null
			},
			totalValue: Number,
			currentValue: {
				type: Number,
				default: null
			},
			remainingValue: {
				type: Number,
				default: null
			},
			separator: {
				type: String,
				default: '/'
			},
			bytes: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			progressValue() { // Used to determine the progress value, depending on whether the current value or remaining value was provided.
				let currentValue = 0;
				if(this.currentValue !== null) { // If a current value was provided, we just need to return the value as is.
					currentValue = this.currentValue;
				} else if(this.remainingValue !== null) { // If the remaining value was provided, then the current value is the total value minus the remaining value.
					currentValue = this.totalValue - this.remainingValue;
				}
				
				return currentValue;
			},
			progressPercentage() { // Returns the progress value as a percentage of the total value.
				return (this.progressValue / this.totalValue * 100).toFixed(0);
			}
		},
		methods: {
			formatValue(value) { // Formats the given value as a human readable size if the progress bar is for data usage, otherwise returns the value as is.
				if(value == 0) {
					return this.bytes ? 'No data' : 0;
				} else {
					return this.bytes ? this.formatBytes(value) : value;
				}
			},
			formatBytes(bytes) { // Converts a number of bytes to a human readable size.
				// Determine the unit of the given bytes value based on the number of powers of 1024.
				const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				const unit = Math.floor(Math.log(bytes) / Math.log(1024));
				
				// Convert the bytes value to the given unit, and return a string with the new value and the unit.
				const value = (bytes / Math.pow(1024, unit)).toFixed(0);
				return `${value} ${units[unit]}`;
			}
		}
	}
</script>

<style scoped lang="scss">
	.title {
		font-weight:bold;
		
		&::after {
			content:":";
		}
	}
	
	.progress-wrapper {
		border:5px solid var(--standard-border-color);
		background-color:var(--highlight-color-dark);
		height:50px;
		
		.progress {
			background-color:var(--main-accent-color);
			height:100%;
		}
		
		.progress-percentage {
			text-align:center;
			font-size:2rem;
			font-weight:bold;
			
			&:not(.no-data) {
				margin-top:-38px;
			}
		}
	}
</style>