<template>
	<modal-view title="Service Settings" :full-width="true" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="saving || loadingService || loadingServiceSettings" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="completed" class="success-message">The request to update the service settings for the given service has been sent successfully.</div>
		<div v-else>
			<form v-if="isActiveService && serviceSettings.length > 0" class="data-form" @submit.prevent="updateServiceSettings">
				<div id="service-settings">
					<fieldset v-for="category in serviceSettings">
						<legend>{{category.display_name}}</legend>
						<div v-for="feature in category.features">
							<div class="checkboxes">
								<label><input type="checkbox" :value="true" v-model="feature.enabled" @change="errorMessage = null" /> {{feature.display_name}}</label>
							</div>
							<div v-if="feature.settings" v-show="feature.enabled">
								<div v-if="feature.enabledInitial" v-for="setting in feature.settings">
									<label :for="`feature-setting-${feature.feature_id}-${setting.setting_id}`">{{setting.display_name}}</label>
									<select v-if="setting.options" :id="`feature-setting-${feature.feature_id}-${setting.setting_id}`" v-model="setting.current_value" @change="errorMessage = null">
										<option v-for="option in setting.options" :value="option.option_id">{{option.display_name}}</option>
									</select>
									<input v-else type="text" :id="`feature-setting-${feature.feature_id}-${setting.setting_id}`" v-model="setting.current_value" @input="errorMessage = null" />
								</div>
								<div v-else class="new-feature-note">Additional settings will be available after saving.</div>
							</div>
						</div>
					</fieldset>
				</div>
				<div class="button-wrapper">
					<button type="submit">Update Service Settings</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				service: null,
				serviceSettings: null,
				saving: false,
				completed: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			loadingService() { // Used to determine whether the updated service details are being loaded from the API.
				return (this.service === null);
			},
			loadingServiceSettings() { // Used to determine whether the service settings are being loaded from the API.
				return (this.serviceSettings === null);
			},
			isActiveService() { // Used to determine whether the given service is active.
				return ['active', 'suspended'].includes(this.service.status);
			},
			apiEndpoint() { // Convenience property to get the API endpoint for managing the given service.
				return `customers/${this.managingCustomer.id}/services/${this.serviceId}`;
			},
			...mapGetters(['managingCustomer'])
		},
		components: {
			ModalView
		},
		async created() { // When the modal is loaded, load the updated service details from the API.
			await this.getServiceDetails();
		},
		methods: {
			async getServiceDetails() { // Performs the API request to get the service details for the given service.
				try {
					// Perform the API request to get the service details for the given service.
					const response = await this.HTTP.get(this.apiEndpoint);
					this.service = response.data.data;
					
					// Check if the given service is active, and if not, display an error message.
					if(!this.isActiveService) {
						this.errorMessage = "The selected service isn't active.";
						this.serviceSettings = []; // Since the non-generic error message is actually in the form, we need to set this to a non-NULL value to hide the loading indicator.
						return;
					}
					
					// Load the service settings for the given service.
					await this.getServiceSettings();
				} catch(error) { // If there was an error obtaining the service details, display the generic error message.
					this.dataError = true;
				}
			},
			async getServiceSettings() { // Performs the API request to get the service settings for the given service.
				try {
					const response = await this.HTTP.get(`${this.apiEndpoint}/settings`);
					this.serviceSettings = this.addFeaturesInitialEnabled(response.data.data);
				} catch(error) { // If there was an error obtaining the service settings, display an error message.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "A settings update order is already in progress for the given service.") { // If the error is that there is already a settings update request in progress for the given service, display the specific error message from the API response.
						this.errorMessage = error.response.data.error;
						this.serviceSettings = []; // Since the non-generic error message is actually in the form, we need to set this to a non-NULL value to hide the loading indicator.
					} else { // For any other error, display the generic error message.
						this.dataError = true;
					}
				}
			},
			async updateServiceSettings() { // Performs the API request to update the service settings for the given service.
				try {
					// Replace the service settings form with a loading indicator.
					this.saving = true;
					
					// Set the data to update the service settings.
					const data = this.setUpdateData();
					if(data === false) {
						this.errorMessage = 'Please ensure that all additional settings for enabled features have a value.';
						return;
					}
					
					// Perform the API request to update the service settings for given service.
					await this.HTTP.post(`${this.apiEndpoint}/settings`, data);
					
					// If the request was successful, display the success message.
					this.completed = true;
				} catch(error) { // If there was an error updating the SIM Card Number, display an error message below the form.
					this.errorMessage = 'An error has occurred.';
				} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
					this.saving = false;
				}
			},
			setUpdateData() { // Validates that all additional settings for enabled features have a value, and returns an object to use in the API request for updating the service settings.
				const updateData = {};
				for(const category of Object.values(this.serviceSettings)) {
					for(const feature of Object.values(category.features)) {
						updateData[feature.feature_id] = {enabled: feature.enabled};
						if(feature.enabledInitial && feature.enabled && feature.settings !== null) { // We only need to add the additional settings for features that are enabled, and were enabled to begin with.
							updateData[feature.feature_id].settings = {};
							for(const setting of Object.values(feature.settings)) {
								// Before adding the setting value to the update data, validate that a value was actually provided.
								if(setting.current_value == '') {
									return false;
								} else if(setting.options !== null) { // For settings that have specific options that must be chosen from, also validate that the given value matches one of the options.
									const validOptions = Object.values(setting.options).map(option => option.option_id);
									if(!validOptions.includes(setting.current_value)) {
										return false;
									}
								}
								
								// Add the setting value to the update data for the given feature.
								updateData[feature.feature_id].settings[setting.setting_id] = setting.current_value;
							}
						}
					}
				}
				
				return updateData;
			},
			addFeaturesInitialEnabled(serviceSettings) { // Given the API response from the getServiceSettings() method, adds an "enabledInitial" property to each feature, setting it to the initial value of the "enabled" property.
				for(const category in serviceSettings) {
					for(const feature in serviceSettings[category].features) {
						serviceSettings[category].features[feature].enabledInitial = serviceSettings[category].features[feature].enabled;
					}
				}
				
				return serviceSettings;
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:100%;
		
		fieldset {
			padding:25px 50px;
		}
		
		input, select {
			width:100%;
		}
	}
	
	#service-settings {
		display:grid;
		grid-template-columns:repeat(2, 1fr);
		gap:30px 50px;
		
		fieldset > div {
			&:not(:last-child) {
				border-bottom:2px solid var(--standard-border-color);
				padding-bottom:20px;
			}
			
			&:not(:first-of-type) {
				padding-top:20px;
			}
		}
		
		.new-feature-note {
			color:#FF0000;
		}
	}
</style>